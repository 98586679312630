import http from "../../utils/http"

/**
 * สิทธิ์การยืนยัน
 * @param {object} params
 */
export function checkisverifier(params) {
    return http({
        url: "/api/verify/checkisverifier",
        data: params,
        forceLogin: true
    })
}

/**
 * หลักฐาน
 * @param {object} params
 */
export function verifyInfo(params) {
    return http({
        url: "/api/verify/verifyInfo",
        data: params,
        forceLogin: true
    })
}

/**
 * การยืนยัน
 * @param {object} params
 */
export function verify(params) {
    return http({
        url: "/api/verify/verify",
        data: params,
        forceLogin: true
    })
}

/**
 * ชนิดการตัดจําหน่าย
 * @param {object} params
 */
export function getVerifyType(params) {
    return http({
        url: "/api/verify/getVerifyType",
        data: params
    })
}

/**
 * บันทึกการตัดจําหน่าย
 * @param {object} params
 */
export function verifyList(params) {
    return http({
        url: "/api/verify/lists",
        data: params,
        forceLogin: true
    })
}
