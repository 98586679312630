<template>
    <el-container class="content" >
        <el-aside width="200px" style="        width: 221px;
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 1px; box-shadow: rgb(0 0 0 / 3%) 0px 4px 12px;
	    border-radius: 13px;
		  

 ">

            <el-menu :default-active="activeIndex" class="menu"  router @open="handleOpen"  :default-openeds="defaultOpeneds" unique-opened   >
                <el-submenu index="1" title style="    background-color: #ffffff;">
                    <template slot="title" style="">
						<i  class="el-icon-undefined" style="margin-right: 24px;     width: 18px;"><svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-029747aa=""><path fill="currentColor" d="M288 320a224 224 0 1 0 448 0 224 224 0 1 0-448 0zm544 608H160a32 32 0 0 1-32-32v-96a160 160 0 0 1 160-160h448a160 160 0 0 1 160 160v96a32 32 0 0 1-32 32z"></path></svg></i>
                        <span style="     font-family: 'sukhumvittadmai2';">ศูนย์สมาชิก</span>
                    </template>
					
                    <el-menu-item index="index"> <i  class="el-icon-undefined" style="margin-right: 3px; width: 16px;
    margin-top: -3px"><svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-029747aa=""><path fill="currentColor" d="M448 68.48v64.832A384.128 384.128 0 0 0 512 896a384.128 384.128 0 0 0 378.688-320h64.768A448.128 448.128 0 0 1 64 512 448.128 448.128 0 0 1 448 68.48z"></path><path fill="currentColor" d="M576 97.28V448h350.72A384.064 384.064 0 0 0 576 97.28zM512 64V33.152A448 448 0 0 1 990.848 512H512V64z"></path></svg></i> ข้อมูลรวม</el-menu-item>
                    <el-menu-item index="info"><i  class="el-icon-undefined" style="margin-right: 3px; width: 16px;
    margin-top: -3px"><svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-029747aa=""><path fill="currentColor" d="M288 320a224 224 0 1 0 448 0 224 224 0 1 0-448 0zm544 608H160a32 32 0 0 1-32-32v-96a160 160 0 0 1 160-160h448a160 160 0 0 1 160 160v96a32 32 0 0 1-32 32z"></path></svg></i> ข้อมูลส่วนบุคคล</el-menu-item>
                    <el-menu-item index="security"> <i  class="el-icon-undefined" style="margin-right: 3px; width: 16px;
    margin-top: -3px"><svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-029747aa=""><path fill="currentColor" d="M329.956 257.138a254.862 254.862 0 0 0 0 509.724h364.088a254.862 254.862 0 0 0 0-509.724H329.956zm0-72.818h364.088a327.68 327.68 0 1 1 0 655.36H329.956a327.68 327.68 0 1 1 0-655.36z"></path><path fill="currentColor" d="M329.956 621.227a109.227 109.227 0 1 0 0-218.454 109.227 109.227 0 0 0 0 218.454zm0 72.817a182.044 182.044 0 1 1 0-364.088 182.044 182.044 0 0 1 0 364.088z"></path></svg></i> ความปลอดภัย</el-menu-item>
                    <el-menu-item index="delivery_address"> <i  class="el-icon-undefined" style="margin-right: 3px; width: 16px;
    margin-top: -3px"><svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-029747aa=""><path fill="currentColor" d="M800 416a288 288 0 1 0-576 0c0 118.144 94.528 272.128 288 456.576C705.472 688.128 800 534.144 800 416zM512 960C277.312 746.688 160 565.312 160 416a352 352 0 0 1 704 0c0 149.312-117.312 330.688-352 544z"></path><path fill="currentColor" d="M512 512a96 96 0 1 0 0-192 96 96 0 0 0 0 192zm0 64a160 160 0 1 1 0-320 160 160 0 0 1 0 320z"></path></svg></i> ที่อยู่จัดส่ง</el-menu-item>
                    <el-menu-item index="collection"> <i  class="el-icon-undefined" style="margin-right: 3px; width: 16px;
    margin-top: -3px"><svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-029747aa=""><path fill="currentColor" d="M192 352h640l64 544H128l64-544zm128 224h64V448h-64v128zm320 0h64V448h-64v128zM384 288h-64a192 192 0 1 1 384 0h-64a128 128 0 1 0-256 0z"></path></svg></i> ที่ฉันชืนชอบ</el-menu-item>
                    <el-menu-item index="footprint"> <i  class="el-icon-undefined" style="margin-right: 3px; width: 16px;
    margin-top: -3px"><svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-029747aa=""><path fill="currentColor" d="M359.168 768H160a32 32 0 0 1-32-32V192H64a32 32 0 0 1 0-64h896a32 32 0 1 1 0 64h-64v544a32 32 0 0 1-32 32H665.216l110.848 192h-73.856L591.36 768H433.024L322.176 960H248.32l110.848-192zM832 192H192v512h640V192zM342.656 534.656a32 32 0 1 1-45.312-45.312L444.992 341.76l125.44 94.08L679.04 300.032a32 32 0 1 1 49.92 39.936L581.632 524.224 451.008 426.24 342.656 534.592z"></path></svg></i> ประวัติของฉัน</el-menu-item>
                </el-submenu>
                <el-submenu index="2" title>
                    <template slot="title">
						<i  class="el-icon-undefined" style="margin-right: 24px;"><svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-029747aa=""><path fill="currentColor" d="M416 896V128h192v768H416zm-288 0V448h192v448H128zm576 0V320h192v576H704z"></path></svg></i>
                        <span style="    font-family: 'sukhumvittadmai2';">ประวัติสั่งซื้อ</span>
                    </template>
                    <el-menu-item index="order_list">คําสั่งซื้อของฉัน</el-menu-item>
                    <el-menu-item index="activist">คืน/หลังการขาย</el-menu-item>
                    <template v-if="is_verify == 1">
                        <el-menu-item index="verification">โต๊ะตรวจสอบยืนยัน</el-menu-item>
                        <el-menu-item index="verification_list">บันทึกการตัดจําหน่าย</el-menu-item>
                    </template>
                </el-submenu>
                <el-submenu index="3" title>
                    <template slot="title"><i  class="el-icon-undefined" style="margin-right: 24px;"><svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" data-v-029747aa=""><path fill="currentColor" d="M512 128 128 447.936V896h255.936V640H640v256h255.936V447.936z"></path></svg></i>
                        <span style="    font-family: 'sukhumvittadmai2';">ศูนย์บัญชี</span>
                    </template>
                    <el-menu-item index="account">ยอดเงินในบัญชี</el-menu-item>
                    <el-menu-item index="withdrawal">บันทึกการถอนเงิน</el-menu-item>
                    <el-menu-item index="my_coupon">คูปองของฉัน</el-menu-item>
                    <el-menu-item index="my_point">คะแนนของฉัน</el-menu-item>
					<el-menu-item index="account_list">รายการบัญชี</el-menu-item>
                    <!-- <el-menu-item index="level">ระดับการเป็นสมาชิก</el-menu-item> -->
                </el-submenu>
            </el-menu>
        </el-aside>
        <el-main class="member">
            <transition name="slide"><router-view /></transition>
        </el-main>
    </el-container>
</template>
<script>
    import { checkisverifier } from "@/api/order/verification"
    export default {
        name: "home",
        components: {},
        data: () => {
            return {
                defaultOpeneds: ["1"],
                activeIndex: "index",
                is_verify: 1
            }
        },
        created() {
            this.activeIndex = this.$route.meta.parentRouter ||this.$route.path.replace("/member/", "")
            this.checkIsVerifier()
        },
        methods: {
            handleOpen(key, keyPath) {
                this.defaultOpeneds = keyPath
            },
            checkIsVerifier() {
                checkisverifier()
                    .then(res => {
                        if (res.data) {
                            this.is_verify = 1
                        }
                    })
                    .catch(err => {
                        this.is_verify = 0
                    })
            }
        },
        watch: {
            $route(curr) {
				this.activeIndex = curr.meta.parentRouter||this.$route.path.replace("/member/", "");
            }
        }
    }
</script>
<style lang="scss" scoped>
    .menu {
        min-height: 730px;
    }
    .content {
        display: flex !important;
        margin: 20px 0;
    }
    .member {
        margin-left: 15px;
    }
	.el-menu {
	    border-radius: 0px;
	
	    border-right: solid 1px #e6e6e600;
	    list-style: none;
	    position: relative;
	    margin: 0;
	    padding-left: 0;
	    background-color: #fff;
	}
	.el-submenu .el-menu-item {
    padding-left: 35px;
    
}.menu {
        height: 762px;
		min-height: 100%;
}.el-menu-item.is-active {
    color: #FE2F2F;
    font-weight: 600;
}.el-submenu .el-menu-item:hover {
   background-color: #fff;
}.el-submenu:hover {
     background-color: #fff !important;
}.el-submenu__title:hover {
    background-color: #f6f6f6 !important;
}.el-submenu.is-active .el-submenu__title {
    border-bottom-color: #fff;
}
</style>
